import _ from "lodash";
export function getNested(obj /*, level1, level2, ... levelN*/) {
  var args = Array.prototype.slice.call(arguments, 1);
  if (!args) return undefined;
  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return undefined;
    }
    obj = obj[args[i]];
  }
  return obj;
}

export function setNested(obj /*, level1, level2, ... levelN, value */) {
  var args = Array.prototype.slice.call(arguments, 1, arguments.length - 1);
  var value = arguments[arguments.length - 1];
  var objSet = Object.assign({}, obj);
  var schema = objSet; // a moving reference to internal objects within obj
  for (var i = 0; i < args.length - 1; i++) {
    var elem = args[i];
    if (!schema[elem]) schema[elem] = _.isNumber(args[i + 1]) ? [] : {};
    schema = schema[elem];
  }
  schema[args[args.length - 1]] = value;
  return objSet;
}

