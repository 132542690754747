import React from "react";
import "./style.css";
import firebase from "firebase";
import { getUserData } from "../../functions/getUserData";
import { MdMenu, MdArrowUpward } from "react-icons/md";
export default class Upload extends React.Component {
  state = { isLoggedIn: false, mobileMode: null, showMenuButtons: false };
  componentDidMount() {
    this.updateResizeMode();
    window.addEventListener("resize", this.updateResizeMode.bind(this));
    this.setState({ loading: true }, () => {
      getUserData().then(response => {
        let { isLoggedIn } = response;
        this.setState({
          isLoggedIn,
          loading: false,
          showMenuButtons: false
        });
      });
    });
  }
  updateResizeMode() {
    let applyMobileStyle =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPod/i) ||
      (window.innerWidth <= 600 && window.innerHeight <= 600);
    this.setState({ mobileMode: applyMobileStyle });
  }
  render() {
    let { children, history, selected } = this.props;
    let { isLoggedIn, mobileMode, showMenuButtons } = this.state;
    let inHome = selected == "home";
    let inVideos = selected == "videos";
    let inNotifications = selected == "notifications";
    let inUpload = selected == "upload";
    let menuButtons = (
      <div className={mobileMode ? "headerDivMob" : "headerDiv"}>
        <button
          className={inHome ? "selectedTab" : "headerButton"}
          style={
            mobileMode
              ? { borderRadius: 10, marginBottom: 5 }
              : {
                  borderBottomLeftRadius: 10,
                  borderTopLeftRadius: 10,
                  borderRightColor: "transparent"
                }
          }
          onClick={() => {
            history.push("/home");
          }}
        >
          Home
        </button>
        <button
          className={inUpload ? "selectedTab" : "headerButton"}
          style={
            mobileMode
              ? { borderRadius: 10, marginBottom: 5 }
              : { borderRightColor: "transparent" }
          }
          onClick={() => {
            history.push("/upload");
          }}
        >
          Upload Video Link
        </button>
        <button
          className={inVideos ? "selectedTab" : "headerButton"}
          style={
            mobileMode
              ? { borderRadius: 10, marginBottom: 5 }
              : { borderRightColor: "transparent" }
          }
          onClick={() => {
            history.push("/videos");
          }}
        >
          Videos
        </button>
        <button
          className={inNotifications ? "selectedTab" : "headerButton"}
          style={
            mobileMode
              ? { borderRadius: 10, marginBottom: 5 }
              : { borderRightColor: "transparent" }
          }
          onClick={() => {
            history.push("/notifications");
          }}
        >
          Send Notification
        </button>
        <button
          className="headerButton"
          style={{ borderRightColor: "transparent" }}
          style={
            mobileMode
              ? { borderRadius: 10, marginBottom: 5 }
              : {
                  borderBottomRightRadius: 10,
                  borderTopRightRadius: 10
                }
          }
          onClick={() => {
            if (window.confirm("Are you sure you want to logout?"))
              firebase
                .auth()
                .signOut()
                .then(() => {
                  history.push("/");
                })
                .catch(() => {
                  history.push("/");
                });
          }}
        >
          Logout
        </button>
      </div>
    );
    return (
      <div
        style={{
          backgroundColor: "#EFF1C2",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          paddingBottom: "20%"
        }}
      >
        <center className="main">
          {isLoggedIn && mobileMode ? (
            <button
              className="menu"
              onClick={() => {
                this.setState({ showMenuButtons: !showMenuButtons });
              }}
            >
              <p className="menuText">Menu</p>
              {showMenuButtons ? (
                <MdArrowUpward color={"#66021C"} size={25} />
              ) : (
                <MdMenu color={"#66021C"} size={25} />
              )}
            </button>
          ) : null}
          {isLoggedIn
            ? mobileMode
              ? showMenuButtons
                ? menuButtons
                : null
              : menuButtons
            : null}
          <img
            alt=""
            src={require("../../images/dotsV.png")}
            className="dotsLeft"
          />
          <div>
            <div className="iqraaImg">
              <img
                alt="Iqraa"
                className="deleteIcon"
                src={require("../../images/iqraa.png")}
                onClick={() => {
                  if (isLoggedIn) history.push("./home");
                }}
              />
            </div>
          </div>
          <img
            alt=""
            src={require("../../images/dotsV.png")}
            className="dotsRight"
          />
          {children}
        </center>
      </div>
    );
  }
}
