import React from "react";
import { getNested } from "../../functions/getNestedFieldFromObj";
import Container from "../container";
import firebase from "firebase";
import { initFirebase } from "../../functions/initFirebase";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { getUserData } from "../../functions/getUserData";
export default class Home extends React.Component {
  state = {
    email: "",
    password: "",
    inputType: "password",
    loading: false,
    isLoggedIn: false,
    errorMessage: "",
    firebaseLoading: false
  };
  componentDidMount() {
    this.setState({ firebaseLoading: true }, () => {
      getUserData().then(response => {
        let { isLoggedIn } = response;
        this.setState({
          isLoggedIn,
          firebaseLoading: false
        });
        if (isLoggedIn) {
          this.props.history.push("/home");
        }
      });
    });
  }
  updateInputValue(type, val) {
    let value = getNested(val, "target", "value");
    type === "email"
      ? this.setState({
          email: value
        })
      : this.setState({
          password: value
        });
  }
  login() {
    let _this=this;
    let { email, password } = this.state;
    this.setState({ loading: true, errorMessage: "" }, () => {
      if (email === "") {
        alert("Enter your email!");
      } else if (password === "") {
        alert("Enter your password!");
      } else {
        initFirebase();
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(res => {
            var user = firebase.auth().currentUser;
            let userId =
              getNested(user, "uid") ||
              getNested(user, "user", "uid") ||
              getNested(user, "_user", "uid");
            this.checkIfRoleIsAdmin(userId).then(result => {
              let role = getNested(result, userId, "role");
              if (role === "admin")
              _this.setState({ loading: false, errorMessage: "" }, () => {
                  this.props.history.push("/home");
                });
              else
              _this.setState(
                  {
                    errorMessage: "You dont have the permission to log in!",
                    loading: false
                  },
                  () => {
                    firebase.auth().signOut();
                  }
                );
            });
          })
          .catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            _this.setState({ loading: false, errorMessage });
            alert("ERRROR: " + errorMessage);
          });
      }
    });
  }
  checkIfRoleIsAdmin(userId) {
    return new Promise((resolve, reject) => {
      var db = firebase.firestore();
      db.collection("children")
        .doc(userId)
        .get()
        .then(res => {
          let data = res.data();
          resolve(data);
        });
    });
  }
  render() {
    let {
      inputType,
      loading,
      errorMessage,
      email,
      password,
      firebaseLoading
    } = this.state;
    return (
      <Container history={this.props.history}>
        {firebaseLoading ? (
          <Loader type="Rings" color="#66021C" height={250} width={250} />
        ) : (
          <div>
            <div>
              <p className="loginText">Email</p>
              <input
                value={email}
                onChange={val => {
                  this.updateInputValue("email", val);
                }}
                type="text"
              />
            </div>
            <div>
              <p className="loginText">Password</p>
              <div className="password">
                <input
                  value={password}
                  onChange={val => {
                    this.updateInputValue("pass", val);
                  }}
                  type={inputType}
                />
                {/* <p
                  className="showText"
                  onClick={() => {
                    this.setState({
                      inputType: inputType == "text" ? "password" : "text"
                    });
                  }}
                >
                  {inputType == "text"
                    ? "Hide" + "   " + " Password!"
                    : "Show  Password!"}
                </p> */}
              </div>
            </div>
            {!loading ? (
              <button
                onClick={() => {
                  this.login();
                }}
                className="buttonHome"
              >
                Login
              </button>
            ) : (
              <Loader type="Rings" color="#66021C" height={250} width={250} />
            )}
            <p style={{ fontWeight: "bold", fontSize: 25, width: "90%" }}>
              {errorMessage}
            </p>
          </div>
        )}
      </Container>
    );
  }
}
