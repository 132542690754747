module.exports = {
  books: [
    {
      numberOfPages: 8,
      size: "14.1",
      id: "sample",
      name: "Sample",
      image: require("../images/iqraSample.png")
    },
    {
      numberOfPages: 32,
      size: "43.3",
      id: "book1",
      name: "Book 1",
      image: require("../images/iqra1.jpg")
    },
    {
      numberOfPages: 30,
      size: "47.1",
      id: "book2",
      name: "Book 2",
      image: require("../images/iqra2.jpg")
    },
    {
      numberOfPages: 32,
      size: "64.3",
      id: "book3",
      name: "Book 3",
      image: require("../images/iqra3.jpg")
    },
    {
      numberOfPages: 30,
      size: "71.0",
      id: "book4",
      name: "Book 4",
      image: require("../images/iqra4.jpg")
    },
    {
      numberOfPages: 30,
      size: "86.7",
      id: "book5",
      name: "Book 5",
      image: require("../images/iqra5.jpg")
    },
    {
      numberOfPages: 31,
      size: "98.4",
      id: "book6",
      name: "Book 6",
      image: require("../images/iqra6.jpg")
    },
    {
      numberOfPages: 8,
      size: "424.9",
      id: "book6in1",
      name: "6 in 1 ",
      image: require("../images/iqra6in1.png")
    }
  ]
};
