import React from "react";
import "./style.css";
import firebase from "firebase";
import _ from "lodash";
import { getNested } from "../../functions/getNestedFieldFromObj";
import Loader from "react-loader-spinner";
import Container from "../container";
import { initFirebase } from "../../functions/initFirebase";
import "./style.css";
import EmptyScreen from "../emptyScreen";
import { books } from "../../data/books";
import { getUserData } from "../../functions/getUserData";
export default class Upload extends React.Component {
  state = {
    selectedBookId: null,
    selectedPageNb: null,
    videoLink: null,
    loading: false,
    isLoggedIn: false,
    firebaseLoading: false,
    description: "",
    mobileMode: false,
    first3Books: [
      {
        numberOfPages: 8,
        size: "14.1",
        id: "sample",
        name: "Sample",
        image: require("../../images/iqraSample.png")
      },
      {
        numberOfPages: 32,
        size: "43.3",
        id: "book1",
        name: "Book 1",
        image: require("../../images/iqra1.jpg")
      },
      {
        numberOfPages: 30,
        size: "47.1",
        id: "book2",
        name: "Book 2",
        image: require("../../images/iqra2.jpg")
      }
    ],
    second3Books: [
      {
        numberOfPages: 32,
        size: "64.3",
        id: "book3",
        name: "Book 3",
        image: require("../../images/iqra3.jpg")
      },
      {
        numberOfPages: 30,
        size: "71.0",
        id: "book4",
        name: "Book 4",
        image: require("../../images/iqra4.jpg")
      },
      {
        numberOfPages: 30,
        size: "86.7",
        id: "book5",
        name: "Book 5",
        image: require("../../images/iqra5.jpg")
      }
    ],
    last2Books: [
      {
        numberOfPages: 31,
        size: "98.4",
        id: "book6",
        name: "Book 6",
        image: require("../../images/iqra6.jpg")
      },
      {
        numberOfPages: 8,
        size: "424.9",
        id: "book6in1",
        name: "6 in 1 ",
        image: require("../../images/iqra6in1.png")
      }
    ]
  };
  componentDidMount() {
    this.updateResizeMode();
    window.addEventListener("resize", this.updateResizeMode.bind(this));
    this.setState({ firebaseLoading: true }, () => {
      getUserData().then(response => {
        let { isLoggedIn } = response;
        this.setState({
          isLoggedIn,
          firebaseLoading: false
        });
      });
    });
  }
  updateResizeMode() {
    let applyMobileStyle =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPod/i) ||
      (window.innerWidth <= 800 && window.innerHeight <= 700);
    this.setState({ mobileMode: applyMobileStyle });
  }
  renderBooks(books) {
    let { selectedBookId, mobileMode } = this.state;
    return (
      <div
        className={
          mobileMode
            ? selectedBookId != null
              ? "bookSelected"
              : "booksUploadMob"
            : selectedBookId != null
            ? "bookSelected"
            : "booksUpload"
        }
      >
        {_.map(books, oneBook => {
          let bookId = getNested(oneBook, "id");
          let bookImage = getNested(oneBook, "image");
          let name = getNested(oneBook, "name");
          let numberOfPages = getNested(oneBook, "numberOfPages");
          let isSelected = selectedBookId && selectedBookId === bookId;
          return isSelected || selectedBookId === null ? (
            <div key={bookId} className="oneBookUpload">
              <p className="titleUpload">{name}</p>
              <img
                alt={name}
                onClick={() => {
                  if (bookId !== "book6in1")
                    this.setState({ selectedBookId: bookId });
                }}
                className={isSelected ? "imgSelected" : "imgUpload"}
                src={bookImage}
              />
              {isSelected && bookId != "book6in1" ? (
                <p className="titleUpload">
                  Number of Pages: {numberOfPages} Pages
                </p>
              ) : null}
            </div>
          ) : null;
        })}
      </div>
    );
  }
  uploadLink() {
    let errors = 0;
    let { selectedBookId, selectedPageNb, videoLink, description } = this.state;
    let selectedBookPagesNb = getNested(
      _.find(books, oneBook => {
        return getNested(oneBook, "id") === selectedBookId;
      }),
      "numberOfPages"
    );
    if (
      isNaN(selectedPageNb) ||
      selectedPageNb <= 0 ||
      selectedPageNb > selectedBookPagesNb
    ) {
      errors++;
      alert("Page number is not valid!");
      this.setLoading(false);
    } else {
      if (selectedBookId === null) {
        alert("Select Book!");
        errors++;
        this.setLoading(false);
      }
      if (selectedPageNb === null) {
        alert("Select Page!");
        errors++;
        this.setLoading(false);
      }
      if (videoLink === null) {
        alert("Insert Video Link!");
        errors++;
        this.setLoading(false);
      }
      if (errors === 0) {
        var db = firebase.firestore();
        db.collection("videos")
          .doc(selectedBookId)
          .set(
            {
              [selectedPageNb]: {
                pageNumber: selectedPageNb,
                videoLink,
                description
              }
            },
            { merge: true }
          )
          .then(res => {
            this.setState(
              {
                loading: false,
                selectedBookId: null,
                selectedPageNb: null,
                videoLink: null,
                description: ""
              },
              () => {
                this.props.history.push("/videos");
              }
            );
          })
          .catch(error => {
            console.log("UPLOAD ERROR", { error });
            alert("Error uploading video Link, plase try again!");
            this.setState({loading:false});
          });
      }
    }
  }
  updateInputValue(type, val) {
    let value = getNested(val, "target", "value");
    type === "page"
      ? this.setState({
          selectedPageNb: value
        })
      : type == "link"
      ? this.setState({
          videoLink: value
        })
      : this.setState({ description: value });
  }
  setLoading(loading) {
    this.setState({ loading });
  }
  checkSelectedBookShelf() {
    let { first3Books, second3Books, last2Books, selectedBookId } = this.state;
    let find1 = _.find(first3Books, oneBook => {
      return getNested(oneBook, "id") === selectedBookId;
    });
    let find2 = _.find(second3Books, oneBook => {
      return getNested(oneBook, "id") === selectedBookId;
    });
    let find3 = _.find(last2Books, oneBook => {
      return getNested(oneBook, "id") === selectedBookId;
    });
    return find1 ? "1" : find2 ? "2" : find3 ? "3" : null;
  }
  render() {
    let {
      selectedBookId,
      first3Books,
      second3Books,
      last2Books,
      loading,
      isLoggedIn,
      firebaseLoading,
      videoLink,
      mobileMode,
      selectedPageNb
    } = this.state;
    let shelfId = this.checkSelectedBookShelf();
    let show = selectedBookId !== null;
    return (
      <Container selected="upload" history={this.props.history}>
        {firebaseLoading ? (
          <Loader type="Rings" color="#66021C" height={250} width={250} />
        ) : isLoggedIn ? (
          <div style={{ paddingBottom: 150 }}>
            <p className="selectBookUpload">
              {selectedBookId ? "" : "Select Book"}
            </p>
            <div
              className={
                selectedBookId != null
                  ? mobileMode
                    ? "SelectedStyleMob"
                    : "SelectedStyle"
                  : ""
              }
            >
              <div className="oneBooksRowUpload">
                {this.renderBooks(first3Books)}
                {// (shelfId && shelfId === "1") ||
                selectedBookId === null ? (
                  <img
                    alt=""
                    src={require("../../images/raf.png")}
                    className="shelfImg"
                  />
                ) : null}
              </div>
              <div className="oneBooksRowUpload">
                {this.renderBooks(second3Books)}
                {// (shelfId && shelfId === "2") ||
                selectedBookId === null ? (
                  <img
                    alt=""
                    src={require("../../images/raf.png")}
                    className="shelfImg"
                  />
                ) : null}
              </div>
              <div>
                {this.renderBooks(last2Books)}
                {// (shelfId && shelfId === "3") ||
                selectedBookId === null ? (
                  <img
                    alt=""
                    src={require("../../images/raf.png")}
                    className="shelfImg"
                  />
                ) : null}
              </div>
              <div>
                {selectedBookId && selectedBookId !== null ? (
                  <div
                    className={mobileMode ? "oneRowUploadMob" : "oneRowUpload"}
                  >
                    <p className="inputTextUpload">Enter Page Number: </p>
                    <input
                      type="text"
                      name="selectedPage"
                      onChange={val => {
                        this.updateInputValue("page", val);
                      }}
                      className={mobileMode ? "inputMob" : ""}
                    />
                  </div>
                ) : null}
                {show ? (
                  <div
                    className={mobileMode ? "oneRowUploadMob" : "oneRowUpload"}
                  >
                    <p className="inputTextUpload">
                      Enter Video Link or Video ID:{" "}
                      <p className="description">
                        (https://www.youtube.com/watch?v=KVZ-P-ZI6W4) Video Link
                        should be in this format
                      </p>
                      <p className="description">
                        (KVZ-P-ZI6W4) Video ID is the charachters after (v=)
                      </p>
                    </p>
                    <input
                      type="text"
                      name="inputLink"
                      onChange={val => {
                        this.updateInputValue("link", val);
                      }}
                      className={mobileMode ? "inputMob" : ""}
                    />
                  </div>
                ) : null}
                {show ? (
                  <div
                    className={mobileMode ? "oneRowUploadMob" : "oneRowUpload"}
                  >
                    <p className="inputTextUpload">Video Description: </p>
                    <textarea
                      onChange={val => {
                        this.updateInputValue("desc", val);
                      }}
                      className={mobileMode ? "textAreaMob" : "textArea"}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {loading ? (
              <div>
                <Loader type="Rings" color="#66021C" height={250} width={250} />
                <p className="titleUpload">Uploading video Link...</p>
              </div>
            ) : selectedBookId != null && videoLink != null && selectedPageNb!=null? (
              <button
                className="buttonUpload"
                onClick={() => {
                  this.setState({ loading: true }, () => {
                    initFirebase();
                    this.uploadLink();
                  });
                }}
              >
                Upload Video Link
              </button>
            ) : null}
          </div>
        ) : (
          <EmptyScreen />
        )}
      </Container>
    );
  }
}
