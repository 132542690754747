import firebase from "firebase";
import { getNested } from "./getNestedFieldFromObj";
import { initFirebase } from "./initFirebase";
import _ from "lodash";
export function getUserData() {
  initFirebase();
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        let displayName = getNested(user, "name");
        let email = getNested(user, "email");
        let emailVerified = getNested(user, "emailVerified");
        let photoURL = getNested(user, "photoURL");
        let isAnonymous = getNested(user, "isAnonymous");
        let uid = getNested(user, "uid");
        let providerData = getNested(user, "providerData");
        let userData = {
          displayName,
          email,
          emailVerified,
          photoURL,
          uid,
          providerData,
          isAnonymous,
          isLoggedIn: true
        };
        firebase
          .firestore()
          .collection("children")
          .doc(uid)
          .get()
          .then(doc => {
            let docData = null;
            if (!_.isEmpty(doc)) docData = doc.data();

            if (!docData) {
              resolve(userData);
            } else {
              displayName = getNested(docData, uid, "name");
              let mergedData = _.merge(userData, docData);
              mergedData.displayName = displayName;
              console.log({
                docData,
                userData,
                mergedData
              });
              resolve(mergedData);
            }
          })
          .catch(error => {
            resolve({ isLoggedIn: false });
            console.log({ error });
          });
      } else {
        resolve({ isLoggedIn: false });
      }
    });
  });
}
