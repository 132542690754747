import firebase from "firebase";
import { getNested } from "./getNestedFieldFromObj";
export function initFirebase() {
  var firebaseConfig = {
    databaseURL: "https://iqraaappnavybits.firebaseio.com",
    apiKey: "AIzaSyDAorchJrx0rJgPVrX3tl6wOKabuUNbEPA",
    authDomain: "iqraaappnavybits.firebaseapp.com",
    projectId: "iqraaappnavybits",
    storageBucket: "iqraaappnavybits.appspot.com",
    messagingSenderId: "36876669128",
    appId: "1:36876669128:ios:90b84b9fbb3d0469"
  };
  if (getNested(firebase, "apps", "length") === 0) {
    firebase.initializeApp(firebaseConfig);
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(
      "BJgm-Q9MDi6IbWe07NH2gN7tbwwKjE0LTUoDQhwKcWiZ8UgZKMp9bLp7TbiIZYb5cq34ZTUs1_Qwzy8W99PVo2s"
    );
  }
}
