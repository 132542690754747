import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
export default class EmptyScreen extends React.Component {
  state = {};
  render() {
    return (
      <div>
        <p className="notificationTitles">You have to Log in! </p>

        <Link className="link" to="/">
          <button onClick={() => {}} className="buttonHome">
            {" "}
            Login{" "}
          </button>
        </Link>
      </div>
    );
  }
}
