import React from "react";
import "./App.css";
import UploadVideo from "../src/screens/uploadVideo/uploadVideo";
import Notifications from "../src/screens/notifications";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "../src/screens/login";
import Home from "../src/screens/home";
import UploadedVideos from "../src/screens/uploadedVideos";
function App() {
  return (
    <Router>
      <Route path="/home" component={Home} />
      <Route path="/upload" component={UploadVideo} />
      <Route path="/notifications" component={Notifications} />
      <Route exact path="/" component={Login} />
      <Route path="/videos" component={UploadedVideos} />
    </Router>
  );
}

export default App;
