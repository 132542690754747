import React from "react";
import firebase from "firebase";
import Container from "../container";
import _ from "lodash";
import { getNested, setNested } from "../../functions/getNestedFieldFromObj";
import EmptyScreen from "../emptyScreen";
import Loader from "react-loader-spinner";
import YouTube from "react-youtube";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { getUserData } from "../../functions/getUserData";
export default class Home extends React.Component {
  state = {
    isLoggedIn: false,
    loading: false,
    videos: [],
    ready: 0,
    editValues: null,
    saving: false,
    mobileMode: false
  };
  componentDidMount() {
    this.updateResizeMode();
    window.addEventListener("resize", this.updateResizeMode.bind(this));
    this.setState({ loading: true }, () => {
      getUserData().then(response => {
        let { isLoggedIn } = response;
        this.getVideos().then(videos => {
          this.setState({
            isLoggedIn,
            loading: false,
            videos
          });
        });
      });
    });
  }
  updateResizeMode() {
    let applyMobileStyle =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPod/i) ||
      (window.innerWidth <= 800 && window.innerHeight <= 700);
    this.setState({ mobileMode: applyMobileStyle });
  }
  getVideos() {
    return new Promise((resolve, reject) => {
      var db = firebase.firestore();
      db.collection("videos")
        .get()
        .then(res => {
          let result = [];
          let docs = res.docs;
          _.forEach(docs, doc => {
            let data = doc.data();
            if (!_.isEmpty(data)) {
              data.id = doc.id;
              result.push(data);
            }
          });
          resolve(result);
        });
    });
  }
  updateInputValue(type, val) {
    let value = getNested(val, "target", "value");
    let editValues = _.cloneDeep(this.state.editValues);
    type === "description"
      ? this.setState({
          editValues: setNested(editValues, "description", value)
        })
      : type == "page"
      ? this.setState({
          editValues: setNested(editValues, "pageNumber", value)
        })
      : this.setState({
          editValues: setNested(editValues, "videoLink", value)
        });
  }
  deleteVideo(bookId, pageNb) {
    return new Promise((resolve, reject) => {
      var db = firebase.firestore();
      let docRef = db.doc(`videos/${bookId}`);
      let update = docRef.update({
        [pageNb]: firebase.firestore.FieldValue.delete()
      });
      resolve({ deleted: true });
    });
  }
  renderVideoRow(oneBook, id) {
    let bookId = getNested(oneBook, "id");
    let newData = _.omit(oneBook, bookId);
    let bookName =
      bookId == "book1"
        ? "Book 1"
        : bookId == "book2"
        ? "Book 2 "
        : bookId == "book3"
        ? "Book 3"
        : bookId == "book4"
        ? "Book 4"
        : bookId == "book5"
        ? "Book 5"
        : bookId == "book6"
        ? "Book 6 "
        : bookId;
    let { ready, editValues, saving, mobileMode } = this.state;
    return _.isEmpty(newData) ? null : (
      <div key={id}>
        <p className="videoTitle">{bookName}</p>
        <div key={bookId} className={mobileMode ? "videosMob" : "videos"}>
          {_.map(oneBook, oneData => {
            if (oneData !== bookId) {
              let description = getNested(oneData, "description");
              let pageNb = getNested(oneData, "pageNumber");
              let link = getNested(oneData, "videoLink");
              let video = "";
              try {
                video = link.split("v=");
              } catch (err) {
                video = link;
              }
              let videoLink = _.size(video) > 0 ? _.last(video) : link;
              let editDescriptionVal = getNested(editValues, "description");
              let editLinkVal = getNested(editValues, "videoLink");
              let editPageNb = getNested(editValues, "pageNumber");
              let isEdit =
                getNested(editValues, "bookId") == bookId &&
                getNested(editValues, "defaultPageNumber") == pageNb;
              return (
                <div
                  key={bookId + "" + pageNb}
                  className={mobileMode ? "rowMob" : "mainVideoRow"}
                >
                  <div className="iconDiv">
                    <MdDeleteForever
                      className="deleteIcon"
                      color={"#66021C"}
                      size={40}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this video?"
                          )
                        ) {
                          this.setState({ loading: true }, () => {
                            this.deleteVideo(bookId, pageNb).then(deleted => {
                              if (deleted)
                                this.getVideos().then(videos => {
                                  this.setState({
                                    loading: false,
                                    videos
                                  });
                                });
                            });
                          });
                        }
                      }}
                    />
                    <MdEdit
                      className="deleteIcon"
                      color={
                        !isEdit && !_.isEmpty(editValues) ? "grey" : "#66021C"
                      }
                      size={40}
                      onClick={() => {
                        if (editValues == null)
                          this.setState({
                            editValues: {
                              bookId,
                              pageNumber: pageNb + "",
                              defaultPageNumber: pageNb + "",
                              description,
                              videoLink
                            }
                          });
                        else if (
                          isEdit &&
                          window.confirm(
                            "Are you sure you want to discard your changes?"
                          )
                        ) {
                          this.setState({ editValues: null });
                        }
                      }}
                    />
                  </div>
                  <div key={bookId + "" + pageNb} className="oneVideo">
                    <div>
                      <YouTube
                        onReady={() => {
                          this.setState({ ready: ready + 1 });
                        }}
                        className="youtubeVideo"
                        videoId={videoLink}
                      />
                    </div>
                    <div className="descriptionDiv">
                      <div
                        className={
                          mobileMode ? "descriptionRowMob" : "descriptionRow"
                        }
                      >
                        <p className="videoDescription">Video Link: </p>
                        {isEdit ? (
                          <input
                            type="text"
                            disabled={!isEdit}
                            value={isEdit ? editLinkVal : videoLink}
                            className={mobileMode ? "editInputSelected" : "videoDescription"}
                            onChange={val => {
                              this.updateInputValue("link", val);
                            }}
                          />
                        ) : (
                          <p className="videoDescription">{videoLink}</p>
                        )}
                      </div>
                    </div>
                    <div className="descriptionDiv">
                      <div
                        className={
                          mobileMode ? "descriptionRowMob" : "descriptionRow"
                        }
                      >
                        <p className="videoDescription">Page Number: </p>
                        {isEdit ? (
                          <input
                            type="text"
                            disabled={!isEdit}
                            value={isEdit ? editPageNb + "" : pageNb}
                            className={mobileMode ? "editInputSelected" : "videoDescription"}
                            onChange={val => {
                              this.updateInputValue("page", val);
                            }}
                          />
                        ) : (
                          <p className="videoDescription"> {pageNb}</p>
                        )}
                      </div>
                      <div
                        className={
                          mobileMode ? "descriptionRowMob" : "descriptionRow"
                        }
                      >
                        <p className="videoDescription">
                          {description || isEdit ? "Description:" : "No Description!"}{" "}
                        </p>
                        {isEdit ? (
                          <textarea
                            disabled={!isEdit}
                            type="text"
                            value={isEdit ? editDescriptionVal : description}
                            className={
                              mobileMode ? "textAreaSelected" : "textArea"
                            }
                            onChange={val => {
                              this.updateInputValue("description", val);
                            }}
                          />
                        ) : (
                          <p className="videoDescription">
                            {description ? description : ""}
                          </p>
                        )}
                      </div>
                      {isEdit ? (
                        <button
                          disabled={saving}
                          className="buttonHome"
                          style={{ width: "90%", marginBottom: 10 }}
                          onClick={() => {
                            this.setState({ saving: true }, () => {
                              this.deleteVideo(bookId, pageNb).then(() => {
                                var db = firebase.firestore();
                                db.collection("videos")
                                  .doc(bookId)
                                  .set(
                                    {
                                      [getNested(
                                        this.state,
                                        "editValues",
                                        "pageNumber"
                                      )]: {
                                        pageNumber: getNested(
                                          this.state,
                                          "editValues",
                                          "pageNumber"
                                        ),
                                        description: getNested(
                                          this.state,
                                          "editValues",
                                          "description"
                                        ),
                                        videoLink: getNested(
                                          this.state,
                                          "editValues",
                                          "videoLink"
                                        )
                                      }
                                    },
                                    { merge: true }
                                  )
                                  .then(() => {
                                    this.setState({ editValues: null }, () => {
                                      this.getVideos().then(videos => {
                                        this.setState({
                                          loading: false,
                                          videos,
                                          saving: false
                                        });
                                      });
                                    });
                                  });
                              });
                            });
                          }}
                        >
                          {saving ? "Saving Changes..." : "Save Changes"}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <img alt="" src={require("../../images/raf.png")} className="rafImg" />
      </div>
    );
  }
  render() {
    let { videos, isLoggedIn, loading, editValues } = this.state;
    return (
      <Container selected="videos" history={this.props.history}>
        {loading ? (
          <Loader type="Rings" color="#66021C" height={250} width={250} />
        ) : isLoggedIn ? (
          _.isEmpty(videos) ? (
            <p style={{ fontWeight: "bold", fontSize: 25 }}>
              No Uploaded Videos!
            </p>
          ) : (
            <div>
              {_.map(videos, (oneData, id) => {
                return this.renderVideoRow(oneData, id);
              })}
            </div>
          )
        ) : (
          <EmptyScreen />
        )}
      </Container>
    );
  }
}
