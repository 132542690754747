import React from "react";
import "./style.css";
import Container from "../container";
import { getUserData } from "../../functions/getUserData";
import EmptyScreen from "../emptyScreen";
import Loader from "react-loader-spinner";
export default class Home extends React.Component {
  state = {
    username: "",
    isLoggedIn: false,
    loading: false,
    mobileMode: false
  };
  componentDidMount() {
    this.updateResizeMode();
    window.addEventListener("resize", this.updateResizeMode.bind(this));
    this.setState({ loading: true }, () => {
      getUserData().then(response => {
        let { displayName, email, isLoggedIn } = response;
        this.setState({
          username: displayName ? displayName : email,
          isLoggedIn,
          loading: false
        });
      });
    });
  }
  updateResizeMode() {
    let applyMobileStyle =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPod/i) ||
      (window.innerWidth <= 600 && window.innerHeight <= 600);
    this.setState({ mobileMode: applyMobileStyle });
  }
  render() {
    let { username, isLoggedIn, loading, mobileMode } = this.state;

    return (
      <Container selected="home" history={this.props.history}>
        {loading ? (
          <Loader type="Rings" color="#66021C" height={250} width={250} />
        ) : isLoggedIn ? (
          <div>
            <div className="homeDiv">
              <p className={mobileMode ? "welcomeMob" : "welcome"}>
                Welcome {username}!
              </p>
              <div className="buttons">
                <button
                  className="buttonHome"
                  onClick={() => {
                    this.props.history.push("/upload");
                  }}
                  style={{ width: mobileMode ? "60%" : "30%" }}
                >
                  Add New Video Link
                </button>
                <button
                  className="buttonHome"
                  onClick={() => {
                    this.props.history.push("/videos");
                  }}
                  style={{ width: mobileMode ? "60%" : "30%" }}
                >
                  View Uploaded Videos
                </button>
                <button
                  className="buttonHome"
                  onClick={() => {
                    this.props.history.push("/notifications");
                  }}
                  style={{ width: mobileMode ? "60%" : "30%" }}
                >
                  Send Notification
                </button>
              </div>
            </div>
          </div>
        ) : (
          <EmptyScreen />
        )}
      </Container>
    );
  }
}
