import React from "react";
import { getNested } from "../../functions/getNestedFieldFromObj";
import Container from "../container";
import firebase from "firebase";
import { initFirebase } from "../../functions/initFirebase";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { getUserData } from "../../functions/getUserData";
import EmptyScreen from "../emptyScreen";
export default class Home extends React.Component {
  state = {
    title: "",
    body: "",
    loading: false,
    isLoggedIn: false,
    firebaseLoading: false,
    mobileMode: false
  };
  componentDidMount() {
    this.updateResizeMode();
    window.addEventListener("resize", this.updateResizeMode.bind(this));
    initFirebase();
    this.setState({ firebaseLoading: true }, () => {
      getUserData().then(response => {
        let { isLoggedIn } = response;
        this.setState({
          isLoggedIn,
          firebaseLoading: false
        });
      });
    });
  }
  updateResizeMode() {
    let applyMobileStyle =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPod/i) ||
      (window.innerWidth <= 600 && window.innerHeight <= 600);
    this.setState({ mobileMode: applyMobileStyle });
  }
  getTokens() {
    return new Promise((resolve, reject) => {
      var db = firebase.firestore();
      db.collection("tokens")
        .get()
        .then(res => {
          let result = [];
          let docs = res.docs;
          _.forEach(docs, doc => {
            let data = doc.data();
            data.id = doc.id;
            result.push(data);
          });
          let tokens = [];
          _.map(result, oneRes => {
            tokens.push(getNested(oneRes, "token"));
          });
          resolve(tokens);
        });
    });
  }
  updateInputValue(type, val) {
    let value = getNested(val, "target", "value");
    type === "title"
      ? this.setState({
          title: value
        })
      : this.setState({
          body: value
        });
  }
  render() {
    let {
      title,
      body,
      loading,
      isLoggedIn,
      firebaseLoading,
      mobileMode
    } = this.state;
    return (
      <Container selected="notifications" history={this.props.history}>
        {firebaseLoading ? (
          <Loader type="Rings" color="#66021C" height={250} width={250} />
        ) : isLoggedIn ? (
          <div>
            {loading ? (
              <div>
                <p className="titleUpload">Sending Notification...</p>
                <Loader type="Rings" color="#66021C" height={250} width={250} />
              </div>
            ) : (
              <div>
                <div className="oneNotificationRow">
                  <p className="notificationTitles">Notification Title</p>
                  <input
                    value={title}
                    onChange={val => {
                      this.updateInputValue("title", val);
                    }}
                    type="text"
                    className={mobileMode ? "notificationInput" : ""}
                  />
                </div>
                <div className="oneNotificationRow">
                  <p className="notificationTitles">Notification Body</p>
                  <textarea
                    value={body}
                    onChange={val => {
                      this.updateInputValue("body", val);
                    }}
                    className={mobileMode ? "notificationTextArea" : "textArea"}
                  />
                </div>
                <button
                  className="buttonHome"
                  onClick={() => {
                    this.getTokens().then(tokensArray => {
                      this.setState({ loading: true }, () => {
                        var functionEndpoint =
                          "https://us-central1-iqraaappnavybits.cloudfunctions.net/sendPublicNotification";
                        fetch(functionEndpoint, {
                          mode: "no-cors",
                          method: "POST",
                          body: JSON.stringify({
                            tokens: tokensArray,
                            title,
                            body
                          }),

                          headers: {
                            "Content-Type": "application/json"
                          }
                        })
                          .then(res => {
                            this.setState({
                              loading: false,
                              title: "",
                              body: ""
                            });
                          })
                          .catch(function(error) {
                            alert(
                              "Error Sending Notification, plase try again!"
                            );
                            this.setState({ loading: false });
                          });
                      });
                    });
                  }}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        ) : (
          <EmptyScreen />
        )}
      </Container>
    );
  }
}
